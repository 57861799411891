import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import {
  addtournamentAction,
  getTournamentLevelListAction,
  getFileUrl,
} from "../Action/action";

const AddTournament = () => {
  const [gettournamentlevellist, settournamentlevelList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [addtournament, setaddtournament] = useState({
    levelIds: [],
    name: "",
    // registerationFees: null,
    startTime: "",
    lateRegisterationTime: 10,
    startingChips: null,
    adminCommission: null,
    // entryFees: "",
    visibility: '',
    is_paid: null,
    rebuy_options: '',
    join_code: '',
    join_code_desc: '',
    prize_pool_range: 0,
    social_media_links: [
      {
        platform: 'twitter',
        link: ''
      },
      {
        platform: 'facebook',
        link: ''
      },
      {
        platform: 'instagram',
        link: ''
      },
      {
        platform: 'discord',
        link: ''
      },
      {
        platform: 'custom_web',
        link: ''
      }
    ]
  });

  const lateRegistTime = [
    {
      title: "5 min",
      value: 5
    },
    {
      title: "10 min",
      value: 10
    },
    {
      title: "15 min",
      value: 15
    },
    {
      title: "20 min",
      value: 20
    },
    {
      title: "25 min",
      value: 25
    },
    {
      title: "30 min",
      value: 30
    },
    {
      title: "35 min",
      value: 35
    },
    {
      title: "40 min",
      value: 40
    },
    {
      title: "45 min",
      value: 45
    },
    {
      title: "50 min",
      value: 50
    },
    {
      title: "55 min",
      value: 55
    },
    {
      title: "60 min",
      value: 60
    },
  ]

  let social_links = []


  useEffect(() => {
    gettournamentlevel();
  }, []);

  const inputHandler1 = (e) => {
    const { name, value, checked } = e.target;
    if (name === "levelIds") {
      if (checked) {
        setaddtournament((prevTournament) => ({
          ...prevTournament,
          levelIds: [...prevTournament.levelIds, value],
        }));
      } else {
        setaddtournament((prevTournament) => ({
          ...prevTournament,
          levelIds: prevTournament.levelIds.filter((id) => id !== value),
        }));
      }
    } else {
      setaddtournament((prevTournament) => ({
        ...prevTournament,
        [name]: value,
      }));
    }
  };

  const gettournamentlevel = async () => {
    let res = await getTournamentLevelListAction({
      skip: 0,
      take: 1000,
    });
    if (res) {
      settournamentlevelList(res);
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (validImageTypes.includes(file.type)) {
        const data = new FormData();
        data.append("file", event.target.files[0]);
        const image = await getFileUrl(data);
        if (image.status) {
          setSelectedImage(image?.data?.url);
          setaddtournament({
            ...addtournament,
            image: image?.data?.meta?.filename || "",
          });
        }
      } else {
        toast.error(
          "Invalid file type. Please select a JPEG, PNG, or JPG image."
        );
      }
    }
  };

  const mediaHandler = (e) => {
    const { name, value } = e.target

    setaddtournament((prev) => {

      let updateSocialLink = prev.social_media_links
      let update = updateSocialLink.map((item) => {
        if (item.platform === name) {
          item.link = value
          return item
        }
        else {
          return item
        }
      })

      console.log(addtournament, 'addtournament')
      return { ...prev, social_media_links: update }
    })

  }

  if (addtournament.social_media_links) {
    addtournament.social_media_links.map((item, ind) => {
      if (item.link === "") {
        social_links.splice(ind, 1)
      }
      else {
        social_links.push(item)
      }

    }
    )
  }


  function validate() {
    if (addtournament.name === "") {
      toast.error("Tournament title is required");
      return false;
    }
    if (addtournament.image === "") {
      toast.error("Tournament Logo is required");
      return false;
    }
    if (addtournament.registerationFees === "") {
      toast.error("Registeration Fees is required");
      return false;
    }
    if (addtournament.is_paid === null) {
      toast.error("Tournament Type field is required");
      return false;
    }
    if (addtournament.adminCommission === "") {
      toast.error("Admin Commission is required");
      return false;
    }
    if (addtournament.startingChips === null) {
      toast.error("Starting Chips is required");
      return false;
    }
    if (addtournament.startTime === "") {
      toast.error("Start Time is required");
      return false;
    }
    // if (addtournament.prize_pool_range === "") {
    //   toast.error("End Time is required");
    //   return false;
    // }
    if (addtournament.lateRegisterationTime === null) {
      toast.error("Late Registeration Time is required");
      return false;
    }
    if (addtournament.join_code_desc === "") {
      toast.error("Join Code Description is required");
      return false;
    }
    if (addtournament.visibility === "Private") {
      if (addtournament.join_code === "") {
        toast.error("Join code field is required");
        return false;
      }
    }
    if (addtournament.rebuy_options === null || addtournament.rebuy_options === '') {
      toast.error("Rebuy field is required");
      return false;
    }
    if (addtournament.visibility === "") {
      toast.error("Visibility is required");
      return false;
    }
    // if (social_links.length === 0) {
    //   toast.error("Enter your website link");
    //   return false;
    // }
    return true;
  }

  let UTC = null

  if (addtournament.startTime) {
    UTC = new Date(addtournament.startTime);
    console.table([addtournament.startTime, UTC.toISOString()])
  }

  const Form = {
    levelIds: addtournament.levelIds,
    name: addtournament.name,
    registerationFees: addtournament.registerationFees,
    startTime: UTC,
    prize_pool_range: Number(addtournament.prize_pool_range),
    lateRegisterationTime: addtournament.lateRegisterationTime,
    startingChips: addtournament.startingChips,
    join_code_desc: addtournament.join_code_desc,
    adminCommission: addtournament.adminCommission,
    profileImage: addtournament.image,
    visibility: addtournament.visibility,
    is_paid: addtournament.is_paid,
    rebuy_options: addtournament.rebuy_options,
    join_code: addtournament.join_code,
    social_media_links: social_links
  };

  if (addtournament.visibility === "Public") {
    delete Form.join_code
  }

  // useEffect(() => {
  //   if (addtournament.visibility === "Public") {
  //     setaddtournament((prev) => ({
  //       ...prev,
  //       join_code: ''
  //     }))
  //   }
  // }, [addtournament])


  const insertTournament = async (e) => {
    e.preventDefault();
    console.log(Form, 'from')
    const isValid = validate();

    if (isValid) {
      console.log(Form, 'form')
      let res = await addtournamentAction(Form);
      if (res) {
        toast.success(res ?? "Tournament Created");
        setSelectedImage('')
        setaddtournament((old) => {

          return {
            levelIds: [],
            name: "",
            registerationFees: "",
            startTime: "",
            lateRegisterationTime: "",
            startingChips: "",
            description: "",
            adminCommission: "",
            profileImage: '',
            prize_pool_range: 0,
            social_media_links: [
              {
                platform: 'twitter',
                link: ''
              },
              {
                platform: 'facebook',
                link: ''
              },
              {
                platform: 'instagram',
                link: ''
              },
              {
                platform: 'discord',
                link: ''
              },
              {
                platform: 'custom_web',
                link: ''
              }
            ]
          };
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.msg);
      }
    }
  };


  return (
    <>
      <div class="wrapper">
        <Toaster />
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-1 ">Add Tournament</h3>
                </div>
              </div>
              <hr />
            </div>
            {/* Content Header (Page header) */}

            {/* Main content */}

            <div className="add-tournament">
              <div className="p-4">
                <div className="box">
                  <form onSubmit={insertTournament}>
                    <div className="modal-body">
                      <div className="container">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter title"
                            onChange={inputHandler1}
                            name="name"
                            value={addtournament.name}
                          />
                        </div>
                        <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Tournament Logo
                            </label>
                            <div className="d-flex">
                              <input
                                type="file"
                                className="form-control"
                                id="exampleFormControlInput1"
                                onChange={handleImageChange}
                                name="profile"
                                accept="image/*"
                              />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={
                                  selectedImage == null ? "#" : selectedImage
                                }
                              >
                                {" "}
                                <img
                                  src={
                                    selectedImage == null
                                      ? "./images/texas/lgogdefault.png"
                                      : selectedImage
                                  }
                                  alt=""
                                  className="ml-3"
                                  width={50}
                                  height={50}
                                />
                              </a>
                            </div>
                          </div>
                          <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Registeration Fees (in $)
                            </label>
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Registeration Fees"
                              onChange={inputHandler1}
                              name="registerationFees"
                              value={addtournament.registerationFees}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Tournament type
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) => inputHandler1(e)}
                              name="is_paid"
                            >
                              <option selected={addtournament.type === ""}>
                                Tournament Type
                              </option>
                              <option value={false}>Free Rolls</option>
                              <option value={true}>Paid</option>
                            </select>
                          </div>
                          <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Admin Commission(%)
                            </label>
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Admin Commission"
                              onChange={inputHandler1}
                              name="adminCommission"
                              value={addtournament.adminCommission}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Starting Chips
                            </label>
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Starting Chips"
                              onChange={inputHandler1}
                              name="startingChips"
                              value={addtournament.startingChips}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                          <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Start Time
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              id="exampleFormControlInput1"
                              onChange={inputHandler1}
                              name="startTime"
                              value={addtournament.startTime}
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Prize Pool Range:<span className="slider-value-holder"> {addtournament.prize_pool_range}</span>
                            </label>

                            <div className="slider-container">
                              <input type="range" min="0" max="100" value={addtournament.prize_pool_range} id="prize_range" onChange={inputHandler1}
                                name="prize_pool_range" className="form-control px-0" placeholder="Select Prize Pool Range" />
                            </div>
                          </div>
                          <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Late Registration Time (in min)
                            </label>
                            <select
                              className="form-control"
                              onChange={inputHandler1}
                              name="lateRegisterationTime"
                              value={addtournament.lateRegisterationTime}
                            >
                              <option selected={addtournament.type === ""}>
                                Select late registration time
                              </option>
                              {
                                lateRegistTime.map((item, index) => <option key={index} value={item.value}>{item.title}</option>)
                              }

                            </select>
                          </div>
                        </div>
                        <div className="mb-3 d-flex w-full">


                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Select Visibility Type
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) => inputHandler1(e)}
                              name="visibility"
                            >
                              <option selected={addtournament.type === ""}>
                                Select Visibility
                              </option>
                              <option value="Public">Public</option>
                              <option value="Private">Private</option>
                            </select>
                          </div>
                          <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Short Text Description
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter short text description"
                              onChange={inputHandler1}
                              name="join_code_desc"
                              value={addtournament.join_code_desc}
                            />
                          </div>

                        </div>

                        <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Select Rebuy
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) => inputHandler1(e)}
                              name="rebuy_options"
                            >
                              <option selected={addtournament.type === ""}>
                                Select Rebuy
                              </option>
                              <option value="Freeze">Freeze</option>
                              <option value="One">One</option>
                              <option value="Three">Three</option>
                              <option value="Unlimited">Unlimited</option>
                            </select>
                          </div>
                          <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput3"
                              className="form-label"
                            >
                              Join Code
                            </label>
                            <input
                              disabled={addtournament.visibility === "Public"}
                              type="text"
                              className={`form-control ${addtournament.visibility === "Public" && 'disabled-input'}`}
                              id="exampleFormControlInput3"
                              placeholder="Enter Join Code"
                              onChange={inputHandler1}
                              name="join_code"
                              // value={addtournament.adminCommission}
                              // onKeyPress={(event) => {
                              //   if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                              //     event.preventDefault();
                              //   }
                              // }}
                              value={addtournament.visibility === "Public" ? '' : addtournament.join_code}
                            />
                          </div>


                        </div>

                        {/* Web links START */}

                        <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="web"
                              className="form-label"
                            >
                              Website link
                            </label>
                            <input
                              type="url"
                              className="form-control"
                              id="web"
                              placeholder="Enter website"
                              onChange={mediaHandler}
                              name="custom_web"
                              value={addtournament.social_media_links[4].link}
                            />
                          </div>
                        </div>

                        {/* Web links END */}




                        <div className="mb-3">
                          <label htmlFor="">Select Tournament Level</label>
                          <div
                            className=" mt-2 bg-white px-3 py-1 newLevel"
                            style={{
                              maxHeight: "150px",
                              overflowY: "auto",
                              borderRadius: "5px",
                              border: "1px solid #e0e1e2",
                            }}
                          >
                            {gettournamentlevellist.length === 0 ? (
                              <div className="text-#494848 text-center py-5">
                                No Level Added in Level Configuration
                              </div>
                            ) : (
                              <>
                                {gettournamentlevellist &&
                                  gettournamentlevellist.map((item, index) => {
                                    return (
                                      <div key={index} className="my-1">
                                        <input
                                          className=""
                                          style={{ display: "none" }}
                                          type="checkbox"
                                          onChange={(e) => inputHandler1(e)}
                                          name="levelIds"
                                          id={item.id}
                                          value={item.id}
                                        />
                                        <label
                                          htmlFor={item.id}
                                          className="text-black"
                                        >
                                          {" "}
                                          {"Small Blinds - " +
                                            item.small_blinds +
                                            " " +
                                            "Big Blinds - " +
                                            item.big_blinds +
                                            " " +
                                            ", " +
                                            "Duration- " +
                                            item.duration +
                                            " " +
                                            ", " +
                                            "Break Duration- " +
                                            item.break_duration +
                                            " "}
                                        </label>
                                      </div>
                                    );
                                  })}{" "}
                              </>
                            )}
                          </div>
                        </div>


                        <div className="modal-footer mt-20">
                          <button type="submit" class="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default AddTournament;

import "./App.css";
import React, { components } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import config from "./coreFIles/config";
import Login from "./component/login";
import Dashboard from "./component/dashboard";
import Users from "./component/users";
import Changepassword from "./component/changepassword";
import Userdetails from "./component/userdetails";
import SupportManagement from "./component/SupportManagement";
import Transactions from "./component/Transactions";
import AddUser from "./component/AddUser";
import Tournament from "./component/Tournament";
import TournamentLevel from "./component/TournamentLevel";
import EditUser from "./component/EditUser";
import AddTournament from "./component/AddTournament";
import EditTournament from "./component/EditTournament";
import WinnerList from "./component/WinnerList";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path={`${config.baseUrl}`} element={<Login />} />
          <Route path={`${config.baseUrl}dashboard`} element={<Dashboard />} />
          <Route path={`${config.baseUrl}users`} element={<Users />} />
          <Route
            path={`${config.baseUrl}addTournamentLevel`}
            element={<TournamentLevel />}
          />

          <Route
            path={`${config.baseUrl}addTournament`}
            element={<AddTournament />}
          />
          <Route
            path={`${config.baseUrl}changepassword`}
            element={<Changepassword />}
          />
          <Route
            path={`${config.baseUrl}supportmanagement`}
            element={<SupportManagement />}
          />
          <Route
            path={`${config.baseUrl}transactions`}
            element={<Transactions />}
          />
          <Route
            path={`${config.baseUrl}tournament`}
            element={<Tournament />}
          />

          <Route path={`${config.baseUrl}adduser`} element={<AddUser />} />

          <Route
            path={`${config.baseUrl}userdetails/:id`}
            element={<Userdetails />}
          />
          <Route
            path={`${config.baseUrl}editUser/:id`}
            element={<EditUser />}
          />
           <Route
            path={`${config.baseUrl}winnerList/:id`}
            element={<WinnerList />}
          />
          <Route
            path={`${config.baseUrl}editTournament/:id`}
            element={<EditTournament />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
// import config from '../coreFIles/config'
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import {
  getTournamentListAction,
  deleteTournamentAction,
} from "../Action/action";
import { toast, Toaster } from "react-hot-toast";
import Swal from "sweetalert2";

const Tournament = () => {
  const [gettournamentlist, settournamentList] = useState([]);
  const [singleLevel, setsingleLevel] = useState({});
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(10);
  const [pageCount, setpageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    gettournament();
  }, [skip, count]);

  const gettournament = async () => {
    let res = await getTournamentListAction({
      skip: skip,
      take: count,
    });
    setpageCount(Math.ceil(res.pagination.count / count));
    if (res) {
      setpageCount(Math.ceil(res.pagination.count / count));
      settournamentList(res.tournaments);
    }
  };

  const handlePageClick = async (data) => {
    setSkip(data.selected * count);
    setDataCount(data.selected);
    const commentsFormServer = await gettournament(skip);
    settournamentList(commentsFormServer.tournaments);
    window.scrollTo(0, 0);
  };

  const tournamentDelete = async (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this User",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteTournamentAction(e.id);
        if (!res.response) {
          gettournament();

          Swal.fire(res, "", "success");
        } else {
          toast.error(res.response.data);
        }
      }
    });
  };

  function editPartner(item) {
    setsingleLevel(item);
  }

  const columns = [
    {
      id: "Sno.",
      name: "S no.",
      selector: (row, index) => index + 1,
    },

    {
      id: "logo",
      name: (
        <>
          <div className="text-center">Logo</div>
        </>
      ),
      selector: (item) => {
        return (
          <div className="text-center">
            <a
              target="_blank"
              rel="noreferrer"
              href={item.profileImage == null ? "#" : item.profileImage}
            >
              {" "}
              <img
                src={
                  item.profileImage == null
                    ? "./images/texas/lgogdefault.png"
                    : item.profileImage
                }
                alt=""
                width={40}
                height={40}
              />
            </a>
          </div>
        );
      },
    },
    {
      id: "title",
      name: (
        <>
          <div className="text-center">Name</div>
        </>
      ),
      selector: (item) => {
        return `${item.name}`;
      },
      minWidth: "180px",
    },
    {
      id: "start_time",
      name: (
        <>
          <div className="text-center">Start Time</div>
        </>
      ),
      selector: (item) => {
        return `${new Date(item.start_time).toLocaleString(
          "en-US",
          {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }
        )}`;
      },
      minWidth: "170px",
    },
    // {
    //   id: "end_time",
    //   name: (
    //     <>
    //       <div className="text-center">End Time</div>
    //     </>
    //   ),
    //   selector: (item) => {
    //     return `${new Date(item.end_time).toLocaleString(
    //       "en-US",
    //       {
    //         year: "numeric",
    //         month: "short",
    //         day: "2-digit",
    //         hour: "numeric",
    //         minute: "numeric",
    //         hour12: true,
    //         timeZone: 'UTC'
    //       }
    //     )}`;
    //   },
    //   minWidth: "170px",
    // },

    {
      id: "prize_pool_range",
      name: (
        <>
          <div className="text-center">Prize Pool Range</div>
        </>
      ),
      selector: (item) => {
        return `${item.prize_pool_range}`;
      },
      minWidth: "180px",
    },

    {
      id: "late_registeration_time",
      name: (
        <>
          <div className="text-center">Late Registeration</div>
          <div className="text-center">Time</div>
        </>
      ),
      selector: (item) => {
        return `${new Date(item.late_registeration_time).toLocaleString(
          "en-US",
          {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }
        )}`;
      },
      minWidth: "170px",
    },
    {
      id: "starting_chips",
      name: "Starting Chips",
      selector: (item) => {
        return `${item.starting_chips}`;
      },
      minWidth: "120px",
    },
    {
      id: "adminCommission",
      name: (
        <>
          <div className="text-center">Admin Commission</div>
        </>
      ),
      selector: (item) => {
        return `${item.admin_commisssion} %`;
      },
      minWidth: "140px",
    },
    {
      id: "is_completed",
      name: "Status",
      selector: (item) => {
        return (
          <>
            {item.is_completed ? (
              <>
                <div style={{ color: "#008000" }}>
                  <span>Completed</span>
                </div>
              </>
            ) : (
              <>
                <div style={{ color: "red" }}>
                  <span>Upcoming</span>
                </div>
              </>
            )}
          </>
        );
      },
      minWidth: "110px",
    },

    {
      id: "levels",
      name: (
        <>
          <div className="text-center">Level</div>
          <div className="text-center">Configuration</div>
        </>
      ),
      selector: (item) => {
        return (
          <>
            <button
              type="button"
              onClick={() => editPartner(item)}
              className="btn btn-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
            >
              View
            </button>
          </>
        );
      },
      minWidth: "140px",
    },
    {
      id: "winnerlist",
      name: "Winner List",
      selector: (item) => {
        return (
          <a
            href={
              item.is_completed ? `${config.baseUrl}winnerList/${item.id}` : "#"
            } ss
          >
            {" "}
            <button
              disabled={!item.is_completed}
              className="btn btn-primary btn-sm"
            >
              View
            </button>
          </a>
        );
      },
      minWidth: "120px",
    },
    {
      id: "action",
      name: "Action",
      selector: (item) => {
        return (
          <>

            <a
              href={
                !item.is_completed
                  ? `${config.baseUrl}editTournament/${item.id}`
                  : "#"
              }
            >
              <button disabled={item.is_completed} type="button" className="btn btn-primary btn-sm">
                Edit
              </button>
            </a>
            <button
              onClick={() => tournamentDelete(item)}
              className="btn btn-danger mx-1 btn-sm"
            >
              Delete
            </button>
          </>

        );
      },
      minWidth: "140px",
    },
  ];



  return (
    <>
      <div class="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-1">Tournaments</h3>
                </div>
              </div>
              <hr />
            </div>
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Tournament List</h4>

                      <div>
                        <label>Count Per Page : </label>
                        <select
                          onChange={(e) => setCount(e.target.value)}
                          className="px-3 py-1 mx-2 "
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                        <a href={`${config.baseUrl}addTournament`}>
                          <button type="button" className="btn btn-primary">
                            Add
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="box-body">
                      <DataTable
                        fixedHeader={true}
                        columns={columns}
                        striped
                        responsive
                        data={gettournamentlist}
                      />
                    </div>
                    <div className="mx-4">
                      <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*Edit Modal */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-light"
                        id="exampleModalLabel"
                      >
                        Update Tournament
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*Edit Modal Ends */}
              {/*Add Modal */}
              <div
                className="modal fade"
                id="exampleModal1"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-light"
                        id="exampleModalLabel"
                      >
                        Tournament Levels
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body">
                      <div className="container">
                        {singleLevel &&
                          singleLevel.levels &&
                          singleLevel.levels.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="mb-1 d-flex  mt-2"
                                style={{ width: "100%" }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    paddingRight: "5px",
                                  }}
                                >
                                  <label className="text-white">
                                    {/* {index +
                                      1 +
                                      ") " +
                                      "Small Blinds - " +
                                      item.small_blinds +
                                      " , " +
                                      "Big Blinds - " +
                                      item.big_blinds +
                                      " , " +
                                      "Duration - " +
                                      item.duration +
                                      " " +
                                      ", " +
                                      "Break Duration - " +
                                      item.break_duration +
                                      " "} */}

                                    {
                                      `${index + 1})
                                      Small Blinds : ${item.small_blinds},
                                      Big Blinds : ${item.big_blinds},
                                      Duration : ${item.duration},
                                      Break Duration : ${item.break_duration}
                                      `
                                    }
                                  </label>
                                </div>
                                {/* <div style={{ width: "3%" }} className="">
                                  <div className="">
                                    <MdDelete
                                      size={15}
                                      className=""
                                      color="red"
                                      onClick={() => alert("Are you sure ?")}
                                    />
                                  </div>
                                </div> */}
                              </div>
                            );
                          })}

                        <div className="modal-footer mt-20">
                          <button
                            type="button"
                            className="btn btn-secondary closeModal1"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Add Modal Ends */}
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Tournament;

import React, { useEffect, useState } from "react";
import config from '../coreFIles/config'
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import DataTable from "react-data-table-component";
import { getSingleTournamentListAction } from "../Action/action";
import { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";

const WinnerList = () => {
  const { id } = useParams();
  const [winnerList, setwinnerList] = useState([]);

  useEffect(() => {
    getSingletournamentlevel();
  }, [id]);

  const getSingletournamentlevel = async () => {
    let res = await getSingleTournamentListAction(id);
    if (res) {
      setwinnerList(res?.joinTournaments);
    }
  };

  const columns = [
    {
      id: "Sno.",
      name: "S no.",
      selector: (row, index) => index + 1,
    },
    {
      id: "title",
      name: "Winners Name",
      selector: (item) => {
        return (
          <a
            style={{ textDecoration: "none" }}
            href={`${config.baseUrl}userdetails/${item?.user?.id}`}
          >
            {item?.user?.first_name + " " + item?.user?.last_name}
          </a>
        );
      },
    },
    {
      id: "title",
      name: "Rank",
      selector: (item, index) => {
        return index + 1;
      },
    },
    {
      id: "title",
      name: "Amount",
      selector: (item) => {
        return item?.winning_amount;
      },
    },
  ];


  return (
    <>
      <div class="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-1">Winner List</h3>
                </div>
              </div>
              <hr />
            </div>
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Winner List</h4>
                    </div>
                    <div className="box-body">
                    <DataTable
                        fixedHeader={true}
                        columns={columns}
                        striped
                        responsive
                        pagination
                        data={winnerList}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*Edit Modal */}

              {/*Edit Modal Ends */}
              {/*Add Modal */}

              {/*Add Modal Ends */}
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default WinnerList;

import {
  getRequest,
  postRequest,
  patchRequest,
  putRequest,
  deleteRequest,
  postRequestFormData,
} from '../coreFIles/helper';

export const LoginAction = (data) => {
  return postRequest('admin-login', data).then((res) => {
    return res.data;
  });
};

export const removeUserFromTournamentAction = (data) => {
  return postRequest('tournaments/leave', data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getDashboardStatisticsAction = () => {
  return getRequest('transactions/dashboard/stats').then((res) => {
    return res;
  });
};

// export const getlockingdurationAction = (data) => {
//   return postRequest("getlockingduration", data).then((res) => {
//     return res.data;
//   });
// };

export const getUsersListAction = (data) => {
  return getRequest(
    `users/all?take=${data.take}&skip=${data.skip}&type=${data.type}`
  ).then((res) => {
    return res.data;
  });
};
export const getSingleUserAction = (data) => {
  return getRequest(`get-user/${data}`).then((res) => {
    return res.data;
  });
};

export const deleteuserAction = (data) => {
  return deleteRequest('admin/delete/user', data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addticketAnswerAction = (data) => {
  const dataobj = {
    answer: data.answer,
  };
  return patchRequest(`support/answer/${data.id}`, dataobj)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteTournamentLevelAction = (data) => {
  return deleteRequest(`tournaments/delete/durationlevel/${data}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getUsersCountAction = (data) => {
  return getRequest(`users/all?take=1000&type=${data.type}`).then((res) => {
    return res.data;
  });
};

export const UserUpdateAction = (data) => {
  return patchRequest(`admin/users/me`, data).then((res) => {
    return res.data;
  });
};

export const getTournamentListAction = (data) => {
  return getRequest(`tournaments/all?skip=${data.skip}&take=${data.take}`).then(
    (res) => {
      return res.data;
    }
  );
};

export const getTournamentLevelListAction = (data) => {
  return postRequest('tournaments/durationlevel/all', data).then((res) => {
    return res.data;
  });
};

export const loginAsUserAction = (data) => {
  return postRequest('loginAsUser', data).then((res) => {
    return res.data;
  });
};

export const getallticketAction = (data) => {
  return getRequest('support', data).then((res) => {
    return res.data;
  });
};

export const gettournamentdetailsAction = (data) => {
  return postRequest('gettournamentdetails', data).then((res) => {
    return res.data;
  });
};

export const gettournamentdetailsbyidAction = (data) => {
  return postRequest('gettournamentdetailsbyid', data).then((res) => {
    return res.data;
  });
};

export const addtournamentAction = (data) => {
  return postRequest(`tournaments/create/mtt`, data).then((res) => {
    return res.data;
  });
};

export const addtournamentLevelAction = (data) => {
  return postRequest('tournaments/create/durationlevel', data).then((res) => {
    return res.data;
  });
};

export const getSingleTournamentListAction = (data) => {
  return getRequest(`tournaments/get/${data}`).then((res) => {
    return res.data;
  });
};
export const getSingleTournamentPrizepoolAction = (data) => {
  return getRequest(`tournaments/prizepool/data/${data}`).then((res) => {
    return res.data;
  });
};

// export const grantExtraChipsAction = (data, id) => {
//   return postRequest(`admin/grant-chips/${id}`, data).then((res) => {
//     return res.data;
//   });
// };

export const updatetournamentAction = (data, id) => {
  return patchRequest(`tournaments/${id}`, data).then((res) => {
    return res.data;
  });
};

export const getDefaultConfigListAction = (data) => {
  return getRequest(`tournaments/durationlevel/config`).then((res) => {
    return res.data;
  });
};
export const deleteTournamentAction = (data) => {
  return deleteRequest(`tournaments/${data}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getFileUrl = async (data) => {
  var fileInfo = {};
  await postRequest('upload', data)
    .then((res) => {
      fileInfo = res;
    })
    .catch((err) => {
      console.log(err);
    });
  return fileInfo;
};

export const getalltransactionAction = (data) => {
  return getRequest(
    `transactions/admin?take=${data.take}&skip=${data.skip}&type=${data.type}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const changePasswordAction = (data) => {
  return postRequest('users/me/change-password', data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
export const UserBlockAction = (data) => {
  return patchRequest(`users/me/${data.id}`, data).then((res) => {
    return res.data;
  });
};
export const UserUnBlockAction = (data) => {
  return postRequest('userUnblock', data).then((res) => {
    return res.data;
  });
};

export const getuserDetailsAction = (data) => {
  return getRequest(`get-user/${data.id}`).then((res) => {
    return res.data;
  });
};

export const withdrawchipsrequestAction = (data) => {
  return postRequest('withdrawchipsrequest', data).then((res) => {
    return res.data;
  });
};

export const totalpendingchipsAction = (data) => {
  return postRequest('totalpendingchips', data).then((res) => {
    return res.data;
  });
};

export const totalpendingchipsforextendAction = (data) => {
  return postRequest('totalpendingchipsforextend', data).then((res) => {
    return res.data;
  });
};

export const approvepaymentrequestAction = (data) => {
  return postRequest('approvepaymentrequest', data).then((res) => {
    return res.data;
  });
};

export const getcommissionpriceAction = (data) => {
  return postRequest('getcommissionprice', data).then((res) => {
    return res.data;
  });
};

export const updatecommissionpriceAction = (data) => {
  return postRequest('updatecommissionprice', data).then((res) => {
    return res.data;
  });
};

export const rejectpaymentrequestAction = (data) => {
  return postRequest('rejectpaymentrequest', data).then((res) => {
    return res.data;
  });
};

export const requestchipsAction = (data) => {
  return postRequest('requestchips', data).then((res) => {
    return res.data;
  });
};

export const totalamountofagentAction = (data) => {
  return postRequest('totalamountofagent', data).then((res) => {
    return res.data;
  });
};

export const updatewithdrawrequestagentAction = (data) => {
  return postRequest('updatewithdrawrequestagent', data).then((res) => {
    return res.data;
  });
};

export const rejectwithdrawrequestagentAction = (data) => {
  return postRequest('rejectwithdrawrequestagent', data).then((res) => {
    return res.data;
  });
};

export const getChipsPriceAction = (data) => {
  return postRequest('getChipsPrice', data).then((res) => {
    return res.data;
  });
};

export const getagentlistAction = (data) => {
  return postRequest('getagentlist', data).then((res) => {
    return res.data;
  });
};

export const sendchipstoagentAction = (data) => {
  return postRequest('sendchipstoagent', data).then((res) => {
    return res.data;
  });
};

export const sendchipstoplayerAction = (data) => {
  return postRequest('sendchipstoplayer', data).then((res) => {
    return res.data;
  });
};

export const totalchipsofagentAction = (data) => {
  return postRequest('totalchipsofagent', data).then((res) => {
    return res.data;
  });
};

export const adduserbyadminAction = (data) => {
  return postRequest('create-user', data).then((res) => {
    return res.data;
  });
};

export const addcoinbyadminAction = (data) => {
  return postRequest('addcoinbyadmin', data).then((res) => {
    return res.data;
  });
};

export const updatewithdrawamountAction = (data) => {
  return postRequest('updatewithdrawamount', data).then((res) => {
    return res.data;
  });
};

export const updatelockingdurationAction = (data) => {
  return postRequest('updatelockingduration', data).then((res) => {
    return res.data;
  });
};

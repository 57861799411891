import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import DataTable from "react-data-table-component";
import {
  getalltransactionAction,
} from "../Action/action";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";

const Transactions = () => {
  const [getbuyrequestlist, setgetbuyrequestList] = useState([]);
  const [type, setType] = useState("all");
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(10);
  const [pageCount, setpageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    getpartner();
  }, [skip, count, type]);

  const getpartner = async () => {
    let res = await getalltransactionAction({
      skip: skip,
      take: count,
      type: type,
    });
    setpageCount(Math.ceil(res.pagination.count / count));
    if (res) {
      setpageCount(Math.ceil(res.pagination.count / count || 0));
      setgetbuyrequestList(res.allTransactions);
    }
  };

  const alltransaction = async (e) => {
    setType(e.target.value);
  };

  const handlePageClick = async (data) => {
    setSkip(data.selected * count);
    setDataCount(data.selected);
    const commentsFormServer = await getpartner(skip);
    setgetbuyrequestList(commentsFormServer.allTransactions);
    window.scrollTo(0, 0);
  };

  const columns = [
    {
      id: "#",
      name: "Sno.",
      selector: (row, index) => {
        return dataCount * count + index + 1},
      maxWidth:"30px"
    },

    {
      id: "from_user",
      name: "From User",
      selector: (item) => {
        return (
          <>
          {item?.user?.first_name + " " + item?.user?.last_name}
          </>
        )
      },
      minWidth:"250px"
    },
    {
      id: "to_user",
      name: "To User",
      selector: (item) => {
        return (
          <>
          <a href={`${config.baseUrl}userdetails/${item.to_user}`}>{item?.transactionTo?.first_name + " " + item?.transactionTo?.last_name}</a>
          </>
        )
      },
      minWidth:"250px"
    },
    {
      id: "name",
      name: "Transaction Type",
      selector: (item) => {
        return `${item.type == "MGCoin" ? "Gold Coin" : "Silver Coin" }`;
      },
      minWidth:"130px"
    },
    {
      id: "amount",
      name: "Amount",
      selector: (item) => {
        return `${parseFloat(item.amount).toFixed(2)}`;
      },
      minWidth:"100px"
    },
    {
      id: "status",
      name: "Status",
      selector: (item) => {
        return (
          <>
            {item.status ==="COMPLETED" ? (
              <>
                <div style={{ color: "#008000" }}>
                  <span>Completed</span>
                </div>
              </>
            ) : (
              <>
                <div style={{ color: "red" }}>
                  <span>Pending</span>
                </div>
              </>
            )}
          </>
        );
      },
      minWidth: "110px",
    },
 

   
    // {
    //   id: "new_chips",
    //   name: "Gold Coins",
    //   selector: (item) => {
    //     return `${item.new_chips == 0 ? "" : item.new_chips}`;
    //   },
    // },
    // {
    //   id: "new_diamond_chips",
    //   name: "Silver Coins",
    //   selector: (item) => {
    //     return `${item.new_diamond_chips == 0 ? "" : item.new_diamond_chips}`;
    //   },
    // },
    // {
    //   id: "user_transaction_id",
    //   name: "User TransactionId",
    //   selector: (item) => {
    //     return `${
    //       item.user_transaction_id == ""
    //         ? "VADE0B248932"
    //         : item.user_transaction_id
    //     }`;
    //   },
    // },
    // {
    //   id: "datetime",
    //   name: "Date ",
    //   selector: (item) => {
    //     return `${item.datetime.substring(0, 10)}`;
    //   },
    // },
   
  ];



  return (
    <>
      <div class="wrapper">
        <Toaster />

        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-1">Transactions details</h3>
                </div>
              </div>
              <hr />
            </div>
            {/* Content Header (Page header) */}

            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      {/* <h4 className="box-title">Transactions Details</h4> */}
                      <select
                        className="box-title p-2"
                        onChange={(e) => alltransaction(e)}
                      >
                        Total Chips
                        <option value="all">All Transactions </option>
                        <option value="mg">Gold Coin Transaction</option>
                        <option value="silver">Silver Coin Transaction </option>
                      </select>
                    </div>
                    <div className="box-body">
                      <DataTable
                        fixedHeader={true}
                        columns={columns}
                        striped
                        responsive
                        data={getbuyrequestlist}
                      />
                    </div>
                    <div className="mx-4">
                      <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Transactions;

import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { UserUpdateAction, getSingleUserAction } from "../Action/action";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";

const EditUser = () => {
  //   const [singleUser, setsingleUser] = useState({});
  const { id } = useParams();
  const [edituser, setedituser] = useState({});

  const form = {
    email: edituser.email,
    firstname: edituser.first_name,
    lastname: edituser.last_name,
    bio: edituser.bio,
    gender: edituser.gender,
    dateOfBirth: edituser.DOB,
    mobile: edituser.mobile_no,
    userId: id,
  };

  console.log(form, "form data");

  useEffect(() => {
    getUsersList();
  }, [id]);

  const getUsersList = async () => {
    try {
      const res = await getSingleUserAction(id);
      if (res) {
        setedituser(res);
      }
    } catch (error) {}
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setedituser((old) => {
      return { ...old, [name]: value };
    });
  };

  const updateUser = async (e) => {
    e.preventDefault();
    let res = await UserUpdateAction(form);
    if (res) {
      toast.success(res);
    } else {
    }
  };

  return (
    <>
      <div class="wrapper">
        <Toaster />
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-1">Edit User</h3>
                </div>

                {/* <div>{mask}</div> */}
              </div>
              <hr />
            </div>
            {/* Content Header (Page header) */}

            {/* Main content */}

            {/*Edit Modal */}
            <div className="">
              <div className="p-4 ">
                <div className="box">
                  <form onSubmit={updateUser}>
                    <div className="modal-body">
                      <div className="container">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Email
                          </label>
                          <input
                            type="name"
                            className="form-control"
                            onChange={inputHandler}
                            name="email"
                            defaultValue={edituser.email}
                          />
                        </div>

                        <div className="w-full d-flex">
                          <div className="w-half pr-2">
                            {" "}
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                First Name
                              </label>
                              <input
                                type="name"
                                className="form-control"
                                onChange={inputHandler}
                                name="first_name"
                                defaultValue={edituser.first_name}
                              />
                            </div>
                          </div>
                          <div className="w-half pl-2">
                            {" "}
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Last Name
                              </label>
                              <input
                                type="name"
                                className="form-control"
                                onChange={inputHandler}
                                name="last_name"
                                defaultValue={edituser.last_name}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Mobile Number with country code e.g-(+9188888xxxxx)
                          </label>
                          <input
                            type="name"
                            className="form-control"
                            onChange={inputHandler}
                            name="mobile_no"
                            defaultValue={edituser.mobile_no}
                          />
                        </div>

                        <div className="w-full d-flex">
                          <div className="w-half pr-2">
                            {" "}
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Date of Birth (dd/mm/yyyy)
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                onChange={inputHandler}
                                name="DOB"
                                value={edituser.DOB}
                              />
                            </div>
                          </div>
                          <div className="w-half pl-2">
                            {" "}
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Gender
                              </label>
                              <select
                                type="name"
                                className="form-control"
                                onChange={inputHandler}
                                name="gender"
                              >
                                <option value="">Select</option>
                                <option
                                  selected={edituser.gender == "Male"}
                                  value="Male"
                                >
                                  Male
                                </option>
                                <option
                                  selected={edituser.gender == "Feale"}
                                  value="Female"
                                >
                                  Female
                                </option>
                                <option
                                  selected={edituser.gender == "Other"}
                                  value="Other"
                                >
                                  Other
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Bio
                          </label>
                          <input
                            type="name"
                            className="form-control"
                            onChange={inputHandler}
                            name="bio"
                            defaultValue={edituser.bio}
                          />
                        </div>

                        <div className=" ">
                          <button
                            type="submit"
                            class="btn btn-primary my-20 pull-right"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/*Edit Modal Ends */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default EditUser;

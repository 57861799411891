const config = {
  baseUrl: '/',
  // baseUrl: '/admin/',
  // imageUrl :"http://localhost:6089/api/uploads/",
  // apiUrl : "http://localhost:6089/api",
  // "homepage": "http://18.135.33.83/admin/",
  imageUrl: 'http://18.135.33.83/poker/backend/uploads/',
  // apiUrl : "http://18.135.33.83:6089/api",
  apiUrl: 'https://api.texashodl.club/api',
  // apiUrl: 'http://localhost:9001/api',

  /************************************Live************************************* */

  /************************************************************************************** */

  adminAddress: '0x2E45F7Aeb070585d25678baa58e789B7cFb2836f', // For Referral
  // chainId : '0x61',  // Testnet
  chainId: '0x38', // Mainnet
};
export default config;

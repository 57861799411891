import React, { useEffect, useState } from 'react';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import { getDashboardStatisticsAction } from '../Action/action';

const Dashboard = () => {
  const [statistics, setStatistics] = useState({});
  // s
  useEffect(() => {
    getDashboardStatistics();
  }, []);

  const getDashboardStatistics = async () => {
    let res = await getDashboardStatisticsAction();

    if (res) {
      setStatistics(res?.data);
    }
  };

  return (
    <>
      <div class='wrapper'>
        {/* <div id="loader"></div> */}
        <Header />
        <Sidebar />
        <div className='content-wrapper'>
          <div className='container-full'>
            <div className='content-header'>
              <div className='d-flex align-items-center'>
                <div className='me-auto'>
                  <h3 className='page-title mb-1'>Dashboard</h3>
                </div>
              </div>
              <div className='mb-5'>
                <hr />
              </div>
            </div>
            {/* Content Header (Page header) */}
            {/* Main content */}
            <section className='content pt-0'>
              <div className='row'>
                <div className='col-xl-12 col-12'>
                  <div className='row'>
                    <div className='col-lg-4 col-12'>
                      <div className='box'>
                        <div className='box-body'>
                          <div className='no-line-chart d-flex align-items-end justify-content-between'>
                            <div>
                              <p className='mb-0'>
                                <h4>Total Users</h4>
                              </p>
                              <p className='mb-0'>
                                <h5>{statistics?.userCount ?? 0}</h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-12'>
                      <div className='box'>
                        <div className='box-body'>
                          <div className='no-line-chart d-flex align-items-end justify-content-between'>
                            <div>
                              <p className='mb-0'>
                                <h4>Today Registered</h4>
                              </p>
                              <p className='mb-0'>
                                <h5>{statistics?._todayUsers ?? 0}</h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-12'>
                      <div className='box'>
                        <div className='box-body'>
                          <div className='no-line-chart d-flex align-items-end justify-content-between'>
                            <div>
                              <p className='mb-0'>
                                <h4>Total Sent</h4>
                              </p>
                              <p className='mb-0'>
                                <h5>{statistics?.silverSent ?? 0}</h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-12'>
                      <div className='box'>
                        <div className='box-body'>
                          <div className='no-line-chart d-flex align-items-end justify-content-between'>
                            <div>
                              <p className='mb-0'>
                                <h4>Total Gold Sent</h4>
                              </p>
                              <p className='mb-0'>
                                <h5>{statistics?.goldSent ?? 0}</h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-12'>
                      <div className='box'>
                        <div className='box-body'>
                          <div className='no-line-chart d-flex align-items-end justify-content-between'>
                            <div>
                              <p className='mb-0'>
                                <h4>Total silver Sent </h4>
                              </p>
                              <p className='mb-0'>
                                <h5>{statistics?.silverSent ?? 0}</h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-12'>
                      <div className='box'>
                        <div className='box-body'>
                          <div className='no-line-chart d-flex align-items-end justify-content-between'>
                            <div>
                              <p className='mb-0'>
                                <h4>Total Silver Received</h4>
                              </p>
                              <p className='mb-0'>
                                <h5>{statistics?.silverReceived ?? 0}</h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Dashboard;

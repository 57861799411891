import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import config from '../coreFIles/config';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import toast, { Toaster } from 'react-hot-toast';
import {
  updatetournamentAction,
  getSingleTournamentListAction,
  getTournamentLevelListAction,
  getFileUrl,
  removeUserFromTournamentAction,
  getSingleTournamentPrizepoolAction,
} from '../Action/action';

const EditTournament = () => {
  const { id } = useParams();
  const [lateMin, setLateMin] = useState(null);
  const [addtournament, setaddtournament] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [gettournamentlevellist, settournamentlevelList] = useState([]);
  const [tournamentPlayers, settournamentPlayers] = useState([]);
  const [tournamentPrizepool, settournamentPrizepool] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);

  const socialMedia = [];

  const lateRegistTime = [
    {
      title: '5 min',
      value: 5,
    },
    {
      title: '10 min',
      value: 10,
    },
    {
      title: '15 min',
      value: 15,
    },
    {
      title: '20 min',
      value: 20,
    },
    {
      title: '25 min',
      value: 25,
    },
    {
      title: '30 min',
      value: 30,
    },
    {
      title: '35 min',
      value: 35,
    },
    {
      title: '40 min',
      value: 40,
    },
    {
      title: '45 min',
      value: 45,
    },
    {
      title: '50 min',
      value: 50,
    },
    {
      title: '55 min',
      value: 55,
    },
    {
      title: '60 min',
      value: 60,
    },
  ];

  useEffect(() => {
    getSingletournamentlevel();
    gettournamentlevel();
    getSingletournamentPrizepool();
  }, [id]);

  console.log(addtournament, 'addtournament');

  const inputHandler1 = (e) => {
    const { name, value } = e.target;
    setaddtournament((prevTournament) => ({
      ...prevTournament,
      [name]: value,
    }));
  };

  const handleImageChange = async (event) => {
    const file = event?.target?.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (validImageTypes.includes(file.type)) {
        const data = new FormData();
        data.append('file', event?.target?.files[0]);
        const image = await getFileUrl(data);
        if (image.status) {
          setSelectedImage(image?.data?.url);
          setaddtournament({
            ...addtournament,
            image: image?.data?.meta?.filename || '',
          });
        }
      } else {
        toast.error(
          'Invalid file type. Please select a JPEG, PNG, or JPG image.'
        );
      }
    }
  };

  const gettournamentlevel = async () => {
    let res = await getTournamentLevelListAction({
      id: id,
    });
    if (res) {
      settournamentlevelList(res);
    }
  };

  const mediaHandler = (e) => {
    const { name, value } = e.target;
    let updated = socialLinks.map((item) => {
      if (item.platform === name) {
        item.link = value;
        return item;
      } else {
        return item;
      }
    });

    setSocialLinks(updated);

    // socialLinks.find((item) => item.platform === 'twitter')?.link;
  };

  if (socialLinks) {
    socialLinks.map((item, index) => {
      if (item.link === '') {
        socialMedia.splice(index, 1);
      } else {
        socialMedia.push(item);
      }
    });
  }

  const Form = {
    name: addtournament.name,
    type: addtournament.type,
    registeration_fees: addtournament.registeration_fees,
    lateRegisterationTime: Number(addtournament.late_registeration_time),
    startingChips: addtournament.starting_chips,
    distribution: addtournament.distribution,
    blinds: addtournament.blinds,
    adminCommission: addtournament.admin_commisssion,
    profileImage: addtournament.image ? addtournament.image : '',
    levelIds: addtournament.levels,
    visibility: addtournament.visibility,
    is_paid: addtournament.is_paid,
    rebuy_options: addtournament.rebuy_options,
    join_code: addtournament.join_code,
    prize_pool_range: addtournament.prize_pool_range,
    social_media_links: socialMedia,
  };

  const updateTournament = async (e) => {
    e.preventDefault();
    if (Form.profileImage == '') {
      delete Form.profileImage;
    }
    let res = await updatetournamentAction(Form, id);
    if (res) {
      toast.success(res ?? 'Tournament Update');
    } else {
      toast.error(res.msg);
    }
  };

  function getDiff(date1, date2) {
    const diff = Math.abs(
      new Date(date1).getMinutes() - new Date(date2).getMinutes()
    );
    return diff;
  }

  const getSingletournamentlevel = async () => {
    let res = await getSingleTournamentListAction(id);
    if (res) {
      settournamentPlayers(res?.players);
      setaddtournament({
        ...res,
        lateRegisterationTime: getDiff(
          res.late_registeration_time,
          res.start_time
        ),
        levels: res.levels.map((i) => i.id),
      });
      setSocialLinks(res.social_media_links);
    }
  };
  const getSingletournamentPrizepool = async () => {
    let res = await getSingleTournamentPrizepoolAction(id);

    if (res) {
      settournamentPrizepool(res);
    }
  };

  const columns = [
    {
      id: 'Sno.',
      name: 'S no.',
      selector: (row, index) => index + 1,
    },
    {
      id: 'title',
      name: 'Name',
      selector: (item) => {
        return (
          <a href={`${config.baseUrl}userdetails/${item.id} `}>
            {item.first_name + ' ' + item.last_name}
          </a>
        );
      },
    },
    {
      id: 'chips',
      name: 'Silver Coin',
      selector: (item) => {
        return <span>{item.silver_coins}</span>;
      },
    },

    {
      id: 'title',
      name: 'Action',
      selector: (item) => {
        return (
          <>
            <button
              onClick={() => registeredUserRemove(item)}
              className=' blockbutton mx-2'
            >
              Remove
            </button>{' '}
          </>
        );
      },
    },
  ];

  const columnsPrizepool = [
    {
      id: 'Sno.',
      name: 'S no.',
      selector: (row, index) => index + 1,
    },
    {
      id: 'rank',
      name: 'Rank',
      selector: (item) => {
        return <span>{item.placesPaid}</span>;
      },
    },
    {
      id: 'Prize',
      name: 'Prize',
      selector: (item) => {
        return (
          <span>{item.players2to10 === '' ? '0%' : item.players2to10}</span>
        );
      },
    },
  ];

  const registeredUserRemove = async (e) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Remove this User From this Tournament!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#790bd9',
      cancelButtonColor: '#dd3333',
      confirmButtonText: 'Yes, Remove it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await removeUserFromTournamentAction({
          playerId: e.id,
          tournamentId: id,
        });
        if (!res.response) {
          getSingletournamentlevel();
          Swal.fire('User Remove Successfully !', '', 'success');
        } else {
          toast.error(res.response.data);
        }
      }
    });
  };

  // useEffect(() => {
  //   if (addtournament) {
  //     var startDate = new Date(addtournament.late_registeration_time); // Example start date
  //     var endDate = new Date(addtournament.start_time); // Example end date
  //     var difference = endDate.getTime() - startDate.getTime();

  //     // Convert milliseconds to minutes
  //     var minutesDifference = Math.floor(difference / (1000 * 60));

  //     console.log('Minute difference:', minutesDifference);
  //     setLateMin(Math.abs(minutesDifference));
  //     setaddtournament((prev) => ({
  //       ...prev,
  //       lateRegisterationTime: Math.abs(minutesDifference),
  //     }));
  //   }
  // }, []);

  // Date fixes
  let updatedDate = addtournament.start_time?.slice(0, 16);
  let ISODate;
  if (updatedDate) {
    ISODate = new Date(updatedDate)?.toISOString();
  }

  return (
    <>
      <div class='wrapper'>
        <Toaster />
        <Header />
        <Sidebar />
        <div className='content-wrapper'>
          <div className='container-full'>
            {/* Main content */}
            <div className='content-header'>
              <div className='d-flex align-items-center'>
                <div className='me-auto'>
                  <h3 className='page-title mb-1'>Edit Tournament</h3>
                </div>
              </div>
              <hr />
            </div>
            {/* Content Header (Page header) */}

            {/* Main content */}

            {/*Edit Modal */}
            <div className='mt-5 edit-tournament'>
              <div className='px-4 '>
                <div className='box'>
                  <form onSubmit={updateTournament}>
                    <div className='modal-body'>
                      <div className='container'>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='form-label'
                          >
                            Title
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='exampleFormControlInput1'
                            placeholder='Enter title'
                            onChange={inputHandler1}
                            name='name'
                            value={addtournament.name}
                          />
                        </div>
                        <div className='mb-3 d-flex w-full'>
                          <div
                            className='w-half'
                            style={{ paddingRight: '10px' }}
                          >
                            {' '}
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label'
                            >
                              Tournament Logo
                            </label>
                            <div style={{ display: 'flex', gap: '10px' }}>
                              <input
                                type='file'
                                className='form-control'
                                id='exampleFormControlInput1'
                                onChange={handleImageChange}
                                name='profile'
                                accept='image/*'
                              />
                              <img
                                src={
                                  selectedImage === null
                                    ? addtournament?.profileImage
                                    : selectedImage
                                }
                                alt=''
                                width={50}
                                height={50}
                              />
                            </div>
                          </div>
                          <div
                            className='w-half'
                            style={{ paddingLeft: '10px' }}
                          >
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label'
                            >
                              Registeration Fees (in $)
                            </label>
                            <input
                              type='number'
                              min='0'
                              className='form-control'
                              id='exampleFormControlInput1'
                              placeholder='Enter Registeration Fees'
                              onChange={inputHandler1}
                              name='registeration_fees'
                              value={addtournament.registeration_fees}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className='mb-3 d-flex w-full'>
                          <div
                            className='w-half'
                            style={{ paddingRight: '10px' }}
                          >
                            {' '}
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label'
                            >
                              Tournament type
                            </label>
                            <select
                              className='form-control'
                              onChange={(e) => inputHandler1(e)}
                              name='is_paid'
                              value={addtournament.is_paid}
                            >
                              <option selected={addtournament.type === ''}>
                                Tournament Type
                              </option>
                              <option value={false}>Free Rolls</option>
                              <option value={true}>Paid</option>
                            </select>
                          </div>
                          <div
                            className='w-half'
                            style={{ paddingLeft: '10px' }}
                          >
                            {' '}
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label'
                            >
                              Admin Commission(%)
                            </label>
                            <input
                              type='number'
                              min='0'
                              className='form-control'
                              id='exampleFormControlInput1'
                              placeholder='Enter Admin Commission'
                              onChange={inputHandler1}
                              name='admin_commisssion'
                              value={addtournament.admin_commisssion}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className='mb-3 d-flex w-full'>
                          <div
                            className='w-half'
                            style={{ paddingRight: '10px' }}
                          >
                            {' '}
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label'
                            >
                              Starting Chips
                            </label>
                            <input
                              type='number'
                              min='0'
                              className='form-control'
                              id='exampleFormControlInput1'
                              placeholder='Enter Starting Chips'
                              onChange={inputHandler1}
                              name='starting_chips'
                              value={addtournament.starting_chips}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                          <div
                            className='w-half'
                            style={{ paddingLeft: '10px' }}
                          >
                            {' '}
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label'
                            >
                              Start Time
                            </label>
                            <input
                              disabled={true}
                              type='datetime-local'
                              className='form-control'
                              id='exampleFormControlInput1'
                              placeholder='Enter Late Registration Time'
                              onChange={inputHandler1}
                              name='start_time'
                              value={addtournament.start_time?.slice(0, 16)}
                            />
                          </div>
                        </div>
                        <div className='mb-3 d-flex w-full'>
                          <div
                            className='w-half'
                            style={{ paddingRight: '10px' }}
                          >
                            {' '}
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label'
                            >
                              Prize Pool Range:
                              <span className='slider-value-holder'>
                                {' '}
                                {addtournament.prize_pool_range}
                              </span>
                            </label>
                            <div className='slider-container'>
                              <input
                                type='range'
                                min='0'
                                max='100'
                                value={addtournament.prize_pool_range}
                                id='prize_range'
                                onChange={inputHandler1}
                                name='prize_pool_range'
                                className='form-control px-0'
                                placeholder='Select Prize Pool Range'
                              />
                            </div>
                          </div>
                          <div
                            className='w-half'
                            style={{ paddingLeft: '10px' }}
                          >
                            {' '}
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label'
                            >
                              Late Registration Time (in min)
                            </label>
                            <select
                              className='form-control'
                              onChange={(e) =>
                                setaddtournament((prev) => ({
                                  ...prev,
                                  lateRegisterationTime: e.target.value,
                                }))
                              }
                              name='lateRegisterationTime'
                              value={addtournament.lateRegisterationTime}
                            >
                              <option selected={addtournament.type === ''}>
                                Select late registration time
                              </option>
                              {lateRegistTime.map((item, index) => (
                                <option key={index} value={item.value}>
                                  {item.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className='mb-3 d-flex w-full'>
                          <div
                            className='w-half'
                            style={{ paddingRight: '10px' }}
                          >
                            {' '}
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label'
                            >
                              Select Visibility Type
                            </label>
                            <select
                              className='form-control'
                              onChange={(e) => inputHandler1(e)}
                              name='visibility'
                              value={addtournament.visibility}
                            >
                              <option selected={addtournament.type === ''}>
                                Select Visibility
                              </option>
                              <option value='Public'>Public</option>
                              <option value='Private'>Private</option>
                            </select>
                          </div>

                          <div
                            className='w-half'
                            style={{ paddingLeft: '10px' }}
                          >
                            {' '}
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label'
                            >
                              Short Text Description
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='exampleFormControlInput1'
                              placeholder='Enter short text description'
                              onChange={inputHandler1}
                              name='join_code_desc'
                              value={addtournament.join_code_desc}
                            />
                          </div>
                        </div>

                        <div className='mb-3 d-flex w-full'>
                          <div
                            className='w-half'
                            style={{ paddingRight: '10px' }}
                          >
                            {' '}
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label'
                            >
                              Select Rebuy
                            </label>
                            <select
                              className='form-control'
                              onChange={(e) => inputHandler1(e)}
                              name='rebuy_options'
                              value={addtournament.rebuy_options}
                            >
                              <option selected={addtournament.type === ''}>
                                Select Rebuy
                              </option>
                              <option value='Freeze'>Freeze</option>
                              <option value='One'>One</option>
                              <option value='Three'>Three</option>
                              <option value='Unlimited'>Unlimited</option>
                            </select>
                          </div>

                          <div
                            className='w-half'
                            style={{ paddingLeft: '10px' }}
                          >
                            {' '}
                            <label
                              htmlFor='exampleFormControlInput3'
                              className='form-label'
                            >
                              Join Code
                            </label>
                            <input
                              disabled={addtournament.visibility === 'Public'}
                              type='text'
                              className={`form-control ${
                                addtournament.visibility === 'Public' &&
                                'disabled-input'
                              }`}
                              id='exampleFormControlInput3'
                              placeholder='Enter Join Code'
                              onChange={inputHandler1}
                              name='join_code'
                              // value={addtournament.adminCommission}
                              // onKeyPress={(event) => {
                              //   if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                              //     event.preventDefault();
                              //   }
                              // }}
                              value={addtournament.join_code}
                            />
                          </div>
                        </div>

                        {/* Social Media links START */}

                        <div className='mb-3 d-flex w-full'>
                          <div
                            className='w-half'
                            style={{ paddingRight: '10px' }}
                          >
                            {' '}
                            <label htmlFor='web' className='form-label'>
                              Website link
                            </label>
                            <input
                              type='url'
                              className='form-control'
                              id='web'
                              placeholder='Enter website link'
                              onChange={mediaHandler}
                              name='custom_web'
                              value={
                                addtournament?.social_media_links?.find(
                                  (item) => item.platform === 'custom_web'
                                )?.link
                              }
                            />
                          </div>
                        </div>

                        {/* Social Media links END */}

                        <div className='mb-3'>
                          <label htmlFor=''>Select Tournament Level</label>
                          <div
                            className=' mt-2 bg-white px-3 py-1 newLevel'
                            style={{
                              maxHeight: '150px',
                              overflowY: 'auto',
                              borderRadius: '5px',
                              border: '1px solid #e0e1e2',
                            }}
                          >
                            {gettournamentlevellist.length == 0 ? (
                              <div className='text-black text-center py-5'>
                                No Level Added in Level Configuration
                              </div>
                            ) : (
                              <>
                                {gettournamentlevellist?.map((item, index) => {
                                  return (
                                    <div key={index} className='my-1'>
                                      <input
                                        className=''
                                        style={{ display: 'none' }}
                                        type='checkbox'
                                        checked={addtournament?.levels?.includes(
                                          item.id
                                        )}
                                        onChange={(e) => {
                                          const levels = [
                                            ...addtournament.levels,
                                          ];
                                          if (e.target.checked) {
                                            levels.push(e.target.value);
                                            setaddtournament({
                                              ...addtournament,
                                              levels,
                                            });

                                            settournamentlevelList([
                                              ...gettournamentlevellist,
                                            ]);
                                          } else {
                                            let newLevel = levels.filter(
                                              (level) =>
                                                level !== e.target.value
                                            );

                                            setaddtournament({
                                              ...addtournament,
                                              levels: newLevel,
                                            });
                                          }

                                          // const levels = [
                                          //   ...addtournament.levels,
                                          // ];

                                          // if (
                                          //   addtournament?.levels?.indexOf(
                                          //     item.id
                                          //   ) !== -1
                                          // ) {
                                          //   levels.splice(
                                          //     levels.indexOf(item?.id),
                                          //     1
                                          //   );
                                          // } else {
                                          //   levels.push(item?.id);
                                          // }
                                          // setaddtournament({
                                          //   ...addtournament,
                                          //   levels,
                                          // });
                                        }}
                                        name='levels'
                                        id={item.id}
                                        value={item.id}
                                      />
                                      <label
                                        htmlFor={item.id}
                                        className='text-black'
                                      >
                                        {' '}
                                        {'Small Blinds - ' +
                                          item.small_blinds +
                                          ' ' +
                                          'Big Blinds - ' +
                                          item.big_blinds +
                                          ' ' +
                                          ', ' +
                                          'Duration- ' +
                                          item.duration +
                                          ' ' +
                                          ', ' +
                                          'Break Duration- ' +
                                          item.break_duration +
                                          ' '}
                                      </label>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </div>

                        <div className='modal-footer mt-20'>
                          <button type='submit' class='btn btn-primary'>
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* <div
              className="modal fade"
              id="exampleModal1"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel1"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title text-light"
                      id="exampleModalLabel1"
                    >
                      Add Chips Amount
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <form onSubmit={insertTournament}>
                    <div className="modal-body">
                      <div className="container">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Enter Chips Amount
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Chips Amount"
                            onChange={(e) => setgrantChips(e.target.value)}
                            name="amount"
                            onKeyPress={(event) => {
                              if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>

                        <div className="modal-footer mt-20">
                          <button type="submit" class="btn btn-primary">
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary closeModal1"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}

            {/*Edit Modal Ends */}
            {/* /.content */}
            {/* 
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Registered Players</h4>
                    </div>
                    <div className="box-body">
                      <ReactDatatable
                        config={configForTable}
                        records={tournamentPlayers}
                        columns={columns}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/*Add Modal Ends */}
            <section className='content'>
              <div className='row'>
                <div className='col-lg-12 col-12'>
                  <div className='box'>
                    <div className='box-header with-border'>
                      <h4 className='box-title'>
                        Registered Players ({tournamentPlayers.length})
                      </h4>
                      {/* <a
                        href={`${config.baseUrl}itemAdd `}
                        className="btn btn-sm btn-primary add_btn"
                      >
                        Add New
                      </a> */}
                    </div>
                    <div className='box-body'>
                      <DataTable
                        columns={columns}
                        striped
                        responsive
                        data={tournamentPlayers}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/*Add Modal Ends */}
            </section>

            <section className='content'>
              <div className='row'>
                <div className='col-lg-12 col-12'>
                  <div className='box'>
                    <div className='box-header with-border'>
                      <h4 className='box-title'>Prizepool</h4>
                      {/* <a
                        href={`${config.baseUrl}itemAdd `}
                        className="btn btn-sm btn-primary add_btn"
                      >
                        Add New
                      </a> */}
                    </div>
                    <div className='box-body'>
                      <DataTable
                        columns={columnsPrizepool}
                        striped
                        responsive
                        data={tournamentPrizepool}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/*Add Modal Ends */}
            </section>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default EditTournament;

import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import DataTable from "react-data-table-component";
import {
  getTournamentLevelListAction,
  addtournamentLevelAction,
  updatetournamentAction,
  deleteTournamentLevelAction,
} from "../Action/action";
import { toast, Toaster } from "react-hot-toast";
import { MdAdd, MdDelete } from "react-icons/md";
import Swal from "sweetalert2";

const TournamentLevel = () => {
  const [gettournamentlevellist, settournamentlevelList] = useState([]);
  //   const [form, setForm] = useState({});
  const [tournamentlevel, settournamentlevel] = useState({});
  const [tournamentLevels, setTournamentLevels] = useState([
    {
      duration: null,
      blinds: null,
      break_duration: null,
      // levelName: "",
    },
  ]);

  const addInputFields = () => {
    const newTournamentLevels = [
      ...tournamentLevels,
      {
        duration: null,
        blinds: null,
        break_duration: null,
        // levelName: "",
      },
    ];
    setTournamentLevels(newTournamentLevels);
  };

  const removeInputFields = (index) => {
    const newTournamentLevels = [...tournamentLevels];
    newTournamentLevels.splice(index, 1);
    setTournamentLevels(newTournamentLevels);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newTournamentLevels = [...tournamentLevels];
    newTournamentLevels[index] = {
      ...newTournamentLevels[index],
      [name]: value,
    };
    setTournamentLevels(newTournamentLevels);
  };

  useEffect(() => {
    gettournamentlevel();
  }, []);

  const inputHandler = (e) => {
    const { name, value } = e.target;
    settournamentlevel((old) => {
      return { ...old, [name]: value };
    });
  };

  const gettournamentlevel = async () => {
    let res = await getTournamentLevelListAction();
    if (res) {
      settournamentlevelList(res);
    }
  };

  const tournamentlevelDelete = async (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this User!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteTournamentLevelAction(e.id);
        if (!res.response) {
          gettournamentlevel();
          Swal.fire(res, "", "success");
        } else {
          toast.error(res.response.data);
        }
      }
    });
  };

  function editPartner(item) {
    settournamentlevel(item);
  }

  const columns = [
    {
      id: "Sno.",
      name: "Sno.",
      selector: (row, index) => index + 1,
    },
    {
      id: "duration",
      name: "Duration",
      selector: (item) => {
        return `${item.duration}`;
      },
    },
    {
      id: "small_blinds",
      name: "Small Blinds",
      selector: (item) => {
        return `${item.small_blinds}`;
      },
    },
    {
      id: "big_blinds",
      name: "Big Blinds",
      selector: (item) => {
        return `${item.big_blinds}`;
      },
    },
    {
      id: "break_duration",
      name: "Break Duration",
      selector: (item) => {
        return `${item.break_duration}`;
      },
    },
  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: false,
    show_pagination: false,
    button: {
      excel: false,
      print: false,
    },
  };

  const editTournament = async (e) => {
    e.preventDefault();
    let res = await updatetournamentAction(tournamentlevel);
    if (res.success) {
      toast.success(res.msg);
      document.getElementsByClassName("closeModal")[0].click();
      gettournamentlevel();
      // setTimeout(() => {
      //   window.location.href = `${config.baseUrl}userguide`;
      // }, 2000);
    } else {
      toast.error(res.msg);
    }
  };

  const insertTournament = async (e) => {
    e.preventDefault();
    let res = await addtournamentLevelAction({ levels: tournamentLevels });
    console.log(res, "resss");
    if (res[0]?.status) {
      toast.success("Tournament Level Created");
      gettournamentlevel();
      // setTimeout(() => {
      //   window.location.href = `${config.baseUrl}addTournamentLevel`;
      // }, 2000);
      document.getElementsByClassName("closeModal1")[0].click();
    } else {
      toast.error(res.msg);
    }
  };

  const handleClose = () => {
    window.location.href = `${config.baseUrl}addTournamentLevel`;
    // document.getElementsByClassName("closeModal1")[0].click();
  };

  return (
    <>
      <div class="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-1">Tournament Levels</h3>
                </div>
              </div>
              <hr />
            </div>
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Tournament Level List</h4>
                      {/* <button className="btn btn-primary">Add</button> */}
                      <button
                        type="button"
                        onClick={() => console.log("opeenen")}
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#addModal"
                      >
                        Add
                      </button>
                    </div>
                    <div className="box-body">
                      <DataTable
                        fixedHeader={true}
                        columns={columns}
                        striped
                        pagination
                        responsive
                        data={gettournamentlevellist}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*Edit Modal */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-light"
                        id="exampleModalLabel"
                      >
                        Update Tournament
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <form onSubmit={editTournament}>
                      <div className="modal-body">
                        <div className="container">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Level Name
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Level Name"
                              onChange={inputHandler}
                              value={tournamentlevel.levelName}
                              name="levelName"
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Duration
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Duration"
                              onChange={inputHandler}
                              // value={tournamentlevel.duration}
                              name="duration"
                              //   value={addtournament.title}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Blinds
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Blinds"
                              onChange={inputHandler}
                              // value={tournamentlevel.blinds}
                              name="blinds"
                              //   value={addtournament.title}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Break Duration
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Break Duration"
                              onChange={inputHandler}
                              // value={tournamentlevel.break_duration}
                              name="break_duration"
                              //   value={addtournament.title}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>

                          <div className="modal-footer mt-20">
                            <button type="submit" class="btn btn-primary">
                              Submit
                            </button>
                            <button
                              type="button"
                            // className="btn btn-secondary closeModal"
                            // data-bs-dismiss="modal"
                            // onClick={handleClose}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*Edit Modal Ends */}
              {/*Add Modal */}
              <div
                className="modal fade"
                id="addModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-light"
                        id="exampleModalLabel"
                      >
                        Add Tournament Levels
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <form onSubmit={insertTournament}>
                      <div className="modal-body">
                        <div className="container">
                          <div className="mt-4">
                            <b className="text-light">Add New Levels</b>
                          </div>
                          {tournamentLevels &&
                            tournamentLevels.map((level, index) => (
                              <>
                                <div
                                  className="p-3 mt-4 mb-3"
                                  style={{
                                    border: "1px solid #fff",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <div key={index}>
                                    {/* <div className="mb-3">
                                      <label
                                        htmlFor={`levelName${index}`}
                                        className="form-label"
                                      >
                                        Level Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={`levelName${index}`}
                                        value={level.levelName}
                                        placeholder="Enter Level Name"
                                        onChange={(event) =>
                                          handleInputChange(index, event)
                                        }
                                        name="levelName"
                                      />
                                    </div> */}
                                    <div className="mb-3">
                                      <label
                                        htmlFor={`duration${index}`}
                                        className="form-label"
                                      >
                                        Level Duration in Seconds
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={level.duration}
                                        id={`duration${index}`}
                                        placeholder="Enter Level Duration"
                                        onChange={(event) =>
                                          handleInputChange(index, event)
                                        }
                                        name="duration"
                                      />
                                    </div>

                                    {/* Big Blinds */}
                                    <div className="mb-3">
                                      <label
                                        htmlFor={`blinds${index}`}
                                        className="form-label"
                                      >
                                        Level Big Blinds
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={level.big_blinds}
                                        id={`big_blinds${index}`}
                                        placeholder="Enter Level Blinds"
                                        onChange={(event) =>
                                          handleInputChange(index, event)
                                        }
                                        name="big_blinds"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        htmlFor={`blinds${index}`}
                                        className="form-label"
                                      >
                                        Level Small Blinds
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={level.small_blinds}
                                        id={`small_blinds${index}`}
                                        placeholder="Enter Level Blinds"
                                        onChange={(event) =>
                                          handleInputChange(index, event)
                                        }
                                        name="small_blinds"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        htmlFor={`breakDuration${index}`}
                                        className="form-label"
                                      >
                                        Level Break Duration in Seconds
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id={`breakDuration${index}`}
                                        value={level.break_duration}
                                        placeholder="Enter Level Break Duration"
                                        onChange={(event) =>
                                          handleInputChange(index, event)
                                        }
                                        name="break_duration"
                                      />
                                    </div>

                                    {tournamentLevels?.length > 1 && (
                                      <>
                                        <MdDelete
                                          style={{ cursor: "pointer" }}
                                          size={22}
                                          className="mx-1"
                                          color="red"
                                          onClick={() =>
                                            removeInputFields(index)
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            ))}

                          <div style={{ textAlign: "end" }}>
                            {" "}
                            <MdAdd
                              style={{ cursor: "pointer" }}
                              size={22}
                              className="mx-1"
                              color="#fff"
                              onClick={addInputFields}
                            />{" "}
                          </div>

                          <div className="modal-footer mt-20">
                            <button type="submit" class="btn btn-primary">
                              Submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary closeModal1"
                              data-bs-dismiss="modal"
                              onClick={handleClose}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*Add Modal Ends */}
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default TournamentLevel;
